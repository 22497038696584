<template>
  <div class="index">
    <div class="i_title">
      <img :src="webs.logo" alt="" class="i_t_img">
      <div class="i_t_name">{{webs.title}}</div>
    </div>

    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in banner" :key="index">
          <img :src="item.img_url" alt="" class="swiper_img">
        </div>
        <!-- <div class="swiper-slide">Slide 2</div>
              <div class="swiper-slide">Slide 3</div> -->
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>

      <!-- 如果需要导航按钮 -->
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>

      <!-- 如果需要滚动条 -->
      <div class="swiper-scrollbar"></div>
    </div>
    <div class="content">
      <div class="con_l" @mouseover="information=true" @mouseout="information=false">
        <div class="popups" v-show="information">
          <div class="popups_name">
            微信扫一扫
            使用小程序，查看行情信息
          </div>
          <img src="../assets/newSaoyisao.jpg" alt="" class="popups_img">
        </div>
        <div class="con_l_title">
          <img src="../assets/hangqing.png" alt="" class="l_title_img">
          行情信息
        </div>
        <div class="con_data" >
          <div class="data_cons" v-for="(item, index ) in informationList" :key="index" v-show="index < 7" >
            <div class="data_cons-l">
              {{ item.title }}
            </div>
            <div class="data_cons-r">
              {{ item.create_time }}
            </div>
          </div>
        </div>
      </div>
      <div class="con_r">
        <div class="con_r_title">
          <img src="../assets/zuixin.png" alt="" class="r_title_img">
          最新发布
        </div>
        <div class="conr_data"  @mouseover="circle=true" @mouseout="circle=false">
          <div class="popups" v-show="circle">
            <div class="popups_name">
              微信扫一扫
              使用小程序，查看最新发布
            </div>
            <img src="../assets/newSaoyisao.jpg" alt="" class="popups_img">
          </div>
          <div class="con-s" v-for="(item, index ) in circleList" :key="index" v-show="index < 4">
            <div class="img_con">
              <img :src="item.thumb_img" alt="" class="con-s-img">
              <div class="imgcon" v-show="item.type == 2">供应</div>
              <div class="imgcon" style="background: rgba(250, 12, 12, 0.5);" v-show="item.type == 1">求购</div>
            </div>

            <div class="con-s-l">
              <div class="con_s_name">
                {{ item.title }}
              </div>
              <div class="con-s-address">
                {{ item.content }}
              </div>
              <div class="con-s-bom">
                <div :class="item.type == 2 ? 'bom_address' : 'bom_addressd'"> {{ item.province }}</div>
                <div class="bom_time">{{ item.create_time }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uel_con">
      2023塑化网www.suhuanet.cn <span style="cursor:pointer" @click="geturl()">鲁ICP备2022038865号-1</span>
    </div>
  </div>
</template>

<script>

import axios from 'axios'; // 引入axios
import Swiper from "swiper";
import 'swiper/dist/css/swiper.css';
import 'swiper/dist/js/swiper';
export default {
  name: 'HelloWorld',
  data() {
    return {
      informationList: [],
      circleList: [],
      banner: [],
      webs:{},
      information:false,
      circle:false
    }
  },
  created() {
    this.getBanner()
    this.getInformationList()
    this.getCircleList()
    console.log('---------------')
  },
  mounted() {

    new Swiper('.swiper-container', {
      // direction: 'vertical', // 垂直切换选项
      //mousewheel: true, //滚轮
      autoplay: { //自动开始
        delay: 2500, //时间间隔
        disableOnInteraction: false, //*手动操作轮播图后不会暂停*
      },
      loop: true, // 循环模式选项

      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination',
        clickable: true, // 分页器可以点击
      },

      // 如果需要前进后退按钮
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      // 如果需要滚动条
      scrollbar: {
        el: '.swiper-scrollbar',
      },
    })
  },

  methods: {
    geturl() {
      // window.location.href = 'https://beian.miit.gov.cn/#/Integrated/index'
      window.open('https://beian.miit.gov.cn/', '_self')
    },
    getBanner() {
      axios.get('https://app.suhuanet.cn/api/web/index', {

      }).then(res => {
        console.log(res.data.data)
        this.banner = res.data.data.banner
        this.webs=res.data.data.web
        // this.informationList=res.data.data
      })

    },
    getInformationList() {
      axios.get('https://app.suhuanet.cn/api/web/informationList', {

      }).then(res => {
        console.log(res.data.data, '12')
        this.informationList = res.data.data
      })
    },
    getCircleList() {
      axios.get('https://app.suhuanet.cn/api/web/circleList', {

      }).then(res => {
        console.log(res.data.data, '96')
        this.circleList = res.data.data
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
.index {
  /* padding-top: 20px; */
}

.i_title {
  display: flex;
  align-items: center;
  padding-left: 50px;
  /* width: 100%; */
  height: 120px;
  /* background-color: #4184ff; */
  /* border: 1px solid #000; */
}

.i_t_img {
  margin-right: 10px;
  width: 100px;
  height: 100px;
}

.i_t_name {
  font-size: 55px;
  color: #4184ff;
  font-family: 宋体;
  font-weight: 900;
}

.swiper {
  width: 100%;
  height: 300px;
  border: 1px solid #000;
}

.swiper-wrapper {
  width: 100%;
  height: 300px;
}

.swiper_img {
  width: 100%;
  height: 100%;
}

.content {
  margin-top: 30px;
  display: flex;

}

.con_l {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 120px;
}

.popups {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45);
  z-index: 999;
}

.popups_name {
  padding-top: 80px;
  font-size: 26px;
  color: #fff;
}

.popups_img {
  margin-top: 70px;
  width: 240px;
  height: 240px;
}

.con_r {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 120px;
}

.con_l_title {
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 900;
  color: #000;
}

.l_title_img {
  margin-right: 15px;
  width: 50px;
  height: 50px;
}

.con_r_title {
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 900;
  color: #000;
  /* background-color: #4184ff; */
}

.r_title_img {
  margin-right: 15px;
  width: 50px;
  height: 50px;
}

.con_data {
  margin-top: 20px;
  width: 100%;
}

.data_cons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #ccc9c9;
}

.data_cons-l {
  font-size: 18px;
}

.data_cons-r {
  font-size: 18px;
  color: #8f8a8a;
}

.conr_data {
  margin-top: 20px;
  width: 100%;
}

.con-s {
  margin-bottom: 15px;
  display: flex;
  justify-items: center;
  justify-content: space-between;
}

.img_con {
  position: relative;
  margin-right: 15px;
  width: 160px;
  height: 100px;
}

.con-s-img {
  /* margin-right: 15px; */
  width: 160px;
  height: 100px;
}

.imgcon {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 160px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background: rgba(42, 120, 244, 0.5);
  color: #fff;
}

.con-s-l {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 75%;
  height: 100px;
  /* border: 1px solid #000; */
}

.con_s_name {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}

.con-s-address {
  margin: 3px 0;
  height: 45px;
  font-size: 16px;
  font-weight: 400;
  color: #666;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-align: left;


}

.con-s-bom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.bom_address {
  width: 128px;
  height: 24px;
  font-size: 16px;
  color: #2A78F4;
  border: 1px solid #2A78F4;
  border-radius: 12px;

}

.bom_addressd {
  width: 128px;
  height: 24px;
  font-size: 16px;
  color: #FA0C0C;
  border: 1px solid #FA0C0C;
  border-radius: 12px;

}

.bom_time {
  font-size: 14px;
  color: #999;
}

.uel_con {
  margin-top: 30px;
  font-size: 20px;
  color: #666;
}
</style>
